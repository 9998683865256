"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import { addClass, on, find } from '@elements/dom-utils';

export function init () {
    onFind('#js-ajax-form__page-wrapper',function (item) {
        addClass('page-wrapper__scroll-margin', item);
        on('success.ajax-form', (evt) => {
            console.log('Success Ajax Form');
            item.scrollIntoView();
        }, find('.js-ajax-form'));
    });
}
"use strict";
import {on, findAllIn, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-form-tracking', (baseElement) => {
        let formEvent = baseElement.getAttribute('data-form-tracking-event');
        console.log('formEvent', formEvent);
        on('form-validation.submit', (event) => {
            let switchElement = findAllIn('.js-form-tracking__switch', baseElement);
            if(switchElement){
                switchElement.forEach((element) => {
                    findAllIn('.option', element).forEach((option) => {
                        console.log('option', option.innerHTML, hasClass('is-active', option));
                        if(hasClass('is-active', option)){
                            window.dataLayer.push({
                                'event': 'contact_form_sent',
                                'category': option.innerHTML,
                            });
                        }
                    });
                });
            }
            let formEvent = baseElement.getAttribute('data-form-tracking-event');
            console.log('formEvent', formEvent);
            if(formEvent){
                window.dataLayer.push({
                    'event': formEvent,
                    'product_brand': baseElement.getAttribute('data-form-tracking-product_brand'),
                    'product_variant': baseElement.getAttribute('data-form-tracking-product_variant'),
                    'product_category': baseElement.getAttribute('data-form-tracking-product_category'),
                    'action': 'Erfolgreiche Angebot anfrage',
                });
            }
        }, baseElement);
    });
}
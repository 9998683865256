import {onFind, initInScope} from "@elements/init-modules-in-scope";
import {addClass, closest, findIn, on, removeClass, toggleClass, hasClass, find} from "@elements/dom-utils";
import * as formValidation from "@elements/form-validation";

export function init() {
    onFind(".js-contact-form", function (baseElement) {
        let formInstance;
        let switchToggle = findIn('.js-switch-toggle__toggle', baseElement);
        let switchPrivate = findIn('.js-switch-toggle__private', baseElement);
        let companyInput = findIn('.js-contact-form__company input', baseElement);
        let companyLabel = findIn('.js-contact-form__company-label .js-label-required-star', baseElement);
        on('click' , () => {
            if (hasClass('is-active', switchPrivate)) {
                companyInput.removeAttribute('required');
                formValidation.getApi(baseElement).then((formApi) => {
                    formInstance = formApi.getFormInstance();
                    formInstance.destroy();
                    formValidation.init();
                })
                companyLabel.style.display = 'none';
            } else {
                companyInput.setAttribute('required', true);
                formValidation.getApi(baseElement).then((formApi) => {
                    formInstance = formApi.getFormInstance();
                    formInstance.destroy();
                    formValidation.init();
                })
                companyLabel.style.display = 'inline-block';
            }
        }, switchToggle);
    });
}
import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
// Replaced import from package temporarily until the package got fixed
// import * as lightbox from '@elements/lightbox';
import * as lightbox from './libs/@elements/lightbox';
lightbox.init({
        plugins: ['zoom', 'video'],
        zoom: true,
        video: true
    }
);

import * as formValidation from '@elements/form-validation';
formValidation.init({
    trigger: {event: 'false'}
});

import * as scrollSlider from './scripts/scroll-slider';
scrollSlider.init();

import * as video from './scripts/video';
video.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as switchButton from './scripts/switch-button';
switchButton.init();

import * as contactForm from './scripts/contact-form';
contactForm.init();

import * as anchorNav from './scripts/anchor-nav';


import * as affix from "@elements/affix";
affix.init({
        offset: 80,
        breakpoints: {
            768: {
                offset: 116
            },
            1200: {
                offset: 170
            }
        }
    }
);

import * as paginationScroll from './scripts/pagination-scroll';
paginationScroll.init();

import * as formTracking from './scripts/tracking/form-tracking';
formTracking.init();